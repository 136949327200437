// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-bootleg-jsx": () => import("./../../../src/pages/projects/bootleg.jsx" /* webpackChunkName: "component---src-pages-projects-bootleg-jsx" */),
  "component---src-pages-projects-cope-jsx": () => import("./../../../src/pages/projects/cope.jsx" /* webpackChunkName: "component---src-pages-projects-cope-jsx" */),
  "component---src-pages-projects-datapult-jsx": () => import("./../../../src/pages/projects/datapult.jsx" /* webpackChunkName: "component---src-pages-projects-datapult-jsx" */),
  "component---src-pages-projects-docunet-jsx": () => import("./../../../src/pages/projects/docunet.jsx" /* webpackChunkName: "component---src-pages-projects-docunet-jsx" */),
  "component---src-pages-projects-geopass-jsx": () => import("./../../../src/pages/projects/geopass.jsx" /* webpackChunkName: "component---src-pages-projects-geopass-jsx" */),
  "component---src-pages-projects-nftix-jsx": () => import("./../../../src/pages/projects/nftix.jsx" /* webpackChunkName: "component---src-pages-projects-nftix-jsx" */),
  "component---src-pages-projects-prinftcloud-jsx": () => import("./../../../src/pages/projects/prinftcloud.jsx" /* webpackChunkName: "component---src-pages-projects-prinftcloud-jsx" */),
  "component---src-pages-projects-rosen-jsx": () => import("./../../../src/pages/projects/rosen.jsx" /* webpackChunkName: "component---src-pages-projects-rosen-jsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

